import React, { useState, useRef, useEffect } from "react";
import star_icon from "../assets/images/star_icon.svg";
import { ethers } from "ethers";
import { data } from "./dumydata";
import HumanMindsets from '../assets/images/freemint_hero_img.png'
import DivergentsArtifact from '../assets/minting/DI.json'
import ApprovedAddresses from '../assets/minting/addresses.json'
global.Buffer = global.Buffer || require('buffer').Buffer


const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs');

const Hero = () => {

  const networkSwitch     = 'homestead'
  const divergentsAddress = networkSwitch === 'homestead' ? '0x86b919f8a5D94dD8622D30dDcB51FbFe721F22F0' : '0xFC09d80c0A070f711a9F9f464C8E6b09613208eC' // Currently testnet Mainnet address:'0x4bc3f1b06Da13CaeCA89CFb91B799d0B5685C663'
  const divergentsChainId = networkSwitch === 'homestead' ? '0x1' : '0x4' // '0x4' for rinkeby, '0x1' for mainnet
  const divergentsNetwork = networkSwitch === 'homestead' ? 'homestead' : 'rinkeby' // 'rinkeby' for rinkeby, 'homestead' for mainnet
  const providerAccess    = networkSwitch === 'homestead' ? 'H3d3TT7HQz_ZcJl6BNNs1QsSJUDjOPy5' : 'H3d3TT7HQz_ZcJl6BNNs1QsSJUDjOPy5' // 'RZUZ6Sut4K3WdAMjhtqvXzVUs_jQmEJX' for rinkeby,  'H3d3TT7HQz_ZcJl6BNNs1QsSJUDjOPy5' for mainnet

  const metamaskInstalled = (typeof window.ethereum !== 'undefined')
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [characterSelection, setCharacterSelection] = useState([0,0,0,0,0,0,0,0,0,0])
  const [charactersRemaining, setCharactersRemaining] = useState([0,0,0,0,0,0,0,0,0,0])
  const [charactersSoldOut, setCharactersSoldOut] = useState([0,0,0,0,0,0,0,0,0,0])
  const [totalMinted, setTotalMinted] = useState(0)
  const [totalMintedByUser, setTotalMintedByUser] = useState(0)
  const [maxMintableByUser, setMaxMintableByUser] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [saleStatus, setSaleStatus] = useState('Closed')
  const [addressWhitelisted, setAddressWhitelisted] = useState(false)
  const [userMerkleProof, setUserMerkleProof] = useState([])
  const [submittingMintTx, setSubmittingMintTx] = useState(false)

  const provider = new ethers.providers.AlchemyProvider(divergentsNetwork, providerAccess)
  const abi = DivergentsArtifact.abi

  const contract = new ethers.Contract(divergentsAddress, abi, provider)

  const whitelistedPrice = 0.05;
  const normalPrice = 0.07;



  useEffect(() => {
    updateSaleMintingStatus()

  }, [selectedAddress])

  useEffect(() => {
    whitelistedAddress()
    maxMintableByUserCurrently()
    // maxMintableByUserCurrently()
  }, [addressWhitelisted, saleStatus])

  useEffect(() => {
    totalMintCost()
  }, [characterSelection])

  useEffect(() => {
    updateSaleMintingStatus()
  }, [addressWhitelisted])


  const connectToMetaMask = async () => {

    const userAddress = await window.ethereum.request({ method: 'eth_requestAccounts' })
    setSelectedAddress(window.ethereum.selectedAddress)
    const checksummedAddress = ethers.utils.getAddress(window.ethereum.selectedAddress)

    if(ApprovedAddresses.some(elem => elem === checksummedAddress)) {
      await setAddressWhitelisted(addressWhitelisted => true)
      console.log('Address whitelisted')
    } else {
      console.log('Address not on Whitelist')
    }

    maxMintableByUserCurrently()


  }

  const whitelistedAddress = async () => {
    if (addressWhitelisted && saleStatus === 'Whitelist') {

      const checksummedAddress = ethers.utils.getAddress(window.ethereum.selectedAddress)
      const generatedProof = await generateMerkleProof(checksummedAddress)
      // console.log(generatedProof)
      setUserMerkleProof(generatedProof)
    }
  }

  const maxMintableByUserCurrently = async () => {
    if(addressWhitelisted) {
      const getWhitelistMintAvailable = (await contract.approvedFreeMints(selectedAddress)).toNumber()

      console.log(getWhitelistMintAvailable)
      setMaxMintableByUser(getWhitelistMintAvailable)

    }
  }


  // Get sale status / availability and minted from contract
  const updateSaleMintingStatus = async () => {
    const getTotalSupply = (await contract.totalSupply()).toNumber()
    const getCharactersRemaining = await contract.charactersRemaining()
    const getPublicSaleStatus = await contract.isPublicSaleOpen()
     if(selectedAddress) {
      const getWhitelistMintAvailable = (await contract.approvedFreeMints(selectedAddress)).toNumber()

      console.log(getWhitelistMintAvailable)
      setMaxMintableByUser(getWhitelistMintAvailable)

     }
    



    setTotalMinted(getTotalSupply)
    setCharactersRemaining(...[getCharactersRemaining])
    const characterAvailability = await getCharactersRemaining.map(character => {return character = 0 ? 1 : 0})
    // console.log(characterAvailability)
    setCharactersSoldOut(...[characterAvailability])

    // const totalMintableCharactersRemaining = getCharactersRemaining.reduce((a, b) => a + b)
    if (getPublicSaleStatus) {
      setSaleStatus('Public')
    } else {
      setSaleStatus('Closed')
    }

    // maxMintableByUserCurrently()

  }

  // Merkle Tree generator functions
  const hashAccount = (userAddress) => {
    return Buffer.from(
      ethers.utils.solidityKeccak256(["address"],[userAddress]).slice(2),'hex'
    );
  }

  const generateMerkleTree = (addresses) => {
    const merkleTree = new MerkleTree(
      addresses.map(hashAccount),
      keccak256,
      {sortPairs: true}
    );
    // console.log(merkleTree.getHexRoot())
    return merkleTree;
  }

  const generateMerkleProof = (userAddress) => {
    const merkleTree = generateMerkleTree(ApprovedAddresses);
    const proof = merkleTree.getHexProof(
      hashAccount(userAddress));
    return proof;
  }

  // Selection increment & decrement counter
  /*
  Rule:
  For increment:
  Total selection should not be more than max mint allowable
  For each item max item should be the lesser of the max available, max total mintable

  For decrement: Only need to check that item is not 0 (as you can't have negative selection)
  */
  const incSelection = (index) => {
    let characterSelectionArray = characterSelection
    let totalSelected =  characterSelectionArray.reduce((a, b) => a + b)
    if (characterSelectionArray[index] <= 10) {
      if(characterSelectionArray[index] < charactersRemaining[index]) {
        characterSelectionArray[index] = characterSelectionArray[index] + 1
        setCharacterSelection([...characterSelectionArray])
        console.log(characterSelection)
      }
    }
  }


  const decSelection = (index) => {
    let characterSelectionArray = characterSelection
    if(characterSelectionArray[index] > 0) {
      characterSelectionArray[index] = characterSelectionArray[index] - 1
      setCharacterSelection([...characterSelectionArray])
      console.log(characterSelection)
    }
  }

  // Calculate total
  const totalMintCost = () => {
    let totalSelected = characterSelection.reduce((a, b) => a + b)
    if (addressWhitelisted) {
      setTotalPrice ( totalPrice => whitelistedPrice * totalSelected)
    } else {
      setTotalPrice ( totalPrice => normalPrice * totalSelected)
    }

  }


  // Minting functions

  const freeMint = async () => {

    await window.ethereum.request({ method: 'eth_requestAccounts' })
    await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: divergentsChainId }]
    })
    await window.ethereum.request({ method: 'eth_requestAccounts' })

    if(submittingMintTx === true) {
      alert ('Another pending transaction already submitted. Please wait for transaction to complete or reload page to try again')
    }

    // let totalSelected =  characterSelection.reduce((a, b) => a + b)

    setSubmittingMintTx(true)
    try {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
        const signer = provider.getSigner()
        const contractMint = new ethers.Contract(divergentsAddress, abi, signer)

        if(addressWhitelisted) {
          const tx = await contractMint.freeMint()

          alert(`Transaction submitted, please check your metamask wallet for status progress`)

          tx.wait().then(receipt => {
              // console.log(receipt)
              alert(`Transaction hash ${receipt.transactionHash} has been verified`)
          })

        }

        updateSaleMintingStatus()


        
    } catch (err) {
        alert(err.message)
    } finally {
        setSubmittingMintTx(false)
    }

  }

 // Call mint (this function decides which mint function to call)
  const callMint = () => {
      freeMint()
  }




  return (
    <div className=" Hero mt-10 xl:-mt-20 xl:grid xl:grid-cols-2 space-x-10 px-5">
      <div className="relative xl:ml-[7rem] lg:mr-10 mx-auto">
        <img
          src={star_icon}
          alt="star_icon"
          className="absolute w-20 top-[20px] left-[250px] md:w-32 md:top-[5px] md:left-[400px] xl:top-[20px] xl:left-[425px] 2xl:w-40 2xl:left-[600px] " //absolute top-[11rem] left-[15rem] text-divergent-perfume   sm:w-44  sm:flex sm:absolute sm:top-[-87px] sm:left-[380px]
        />
        <p className="text-divergent-green xl:text-lg"> {/*  ml-1 sm:ml-0 text-[0.7rem] w-[22rem] text-center xl:text-justify mt-3 sm:text-[15px] sm:w-[85%] 2xl:text-[23px] */}
          FREE MINT <br />
        </p>
        <h6 className="text-divergent-green font-FaroTrial text-4xl 2xl:text-[5.25rem] md:text-6xl pb-6"> {/* //  */}
        The Divergents {/* The Divergents <br /> 2022 NFTs */}
        </h6>

        <p className="text-divergent-lightgray xl:text-left pb-2 leading-relaxed max-w-prose">
        Dear holders,
        </p>
        <p className="text-divergent-lightgray xl:text-left pb-2 leading-relaxed max-w-prose pb-5">
          You are welcome to make a free mint based on the <span className="text-divergent-green font-FaroTrial">SNAPSHOT taken on July 11</span>. 
          For every NFT you hold by July 11, you can mint another one for free. Connect your wallet and enjoy this special privilege to mint NFTs just for the gas fees.
        </p>
        
        <p className="text-divergent-lightgray"><b>Limited opportunity, Limited time. <span className="text-divergent-green font-FaroTrial">Free mint event ends July 31.</span></b></p>
        
        
        <p className="text-divergent-perfume text-3xl xl:text-4xl font-bold font-FaroTrial py-16">
          MINTED {totalMinted}/2022
        </p>

        <p className="text-divergent-lightgray xl:text-left pb-2 leading-relaxed max-w-prose">
        Free mints available: {maxMintableByUser}
        </p>

        <div className="flex flex-col gap-4 md:items-center lg:flex-row ">
          {/* diverge__button relative ml-1 xl:ml-0 top-[37rem] xl:relative sm:top-0 flex flex-col  gap-4 justify-center items-center xl:justify-start xl:mb-10 xl:flex xl:flex-row xl:gap-4 */}
            <button disabled={maxMintableByUser == 0} onClick={callMint}
                    className="disabled:bg-divergent-black disabled:border bg-divergent-green md:w-80 h-14 font-bold font-FaroTrial hover:bg-divergent-perfume transition duration-0 hover:duration-700">
              MINT
            </button>
            <button onClick={connectToMetaMask}
                    className="truncate bg-divergent-black text-divergent-green border-solid border-2 border-divergent-green md:w-80 h-14 font-bold font-FaroTrial hover:bg-divergent-perfume hover:text-divergent-black hover:border-none transition duration-0 hover:duration-700 px-2">
              {!metamaskInstalled && `MetaMask Not Found`}
              {metamaskInstalled && !selectedAddress && `CONNECT WALLET`}
              {metamaskInstalled && selectedAddress &&
              <>
                  {selectedAddress}
              </>
              }
            </button>

            <p className="lg:hidden text-divergent-green text-center pt-2 text-sm underline">
              <a href="https://metamask.app.link/dapp/freemint.thedivergents.io/" target='_blank'>Connect Metamask or Download Metamask for iOS/Android and access via browser in Metamask app to mint on mobile</a>
            </p>



          </div>

        <p className="hidden lg:block text-divergent-lightgray pt-2 text-sm">
        Note: a maximum of 10 is mintable at one time. If you have more than 10 eligible mints, you will be able to mint the remainder in another transaction.
        
        </p>

        <p className="hidden lg:block text-divergent-lightgray pt-2 text-sm">
        Join us on <a href="http://discord.gg/thedivergents" className="text-divergent-perfume">Discord</a> to explore the Divergent world and meet other Divergents. 
        
        </p>
        <p className="hidden lg:block text-divergent-lightgray text-sm">
          Reveal of the NFTs on Sunday. 
        </p>


      </div>
      
      <div className=""> {/* Hero__rightside relative pt-12 xl:mt-10 xl:ml-[10rem] xl:mr-[7rem] lg:relative lg:top-0 flex flex-col lg:p-0 gap-[0.63rem] lg:flex lg:flex-col lg:gap-[0.63rem] 2xl:mr-[150px] */}
        <img
            src={HumanMindsets}
            alt="Human Mindsets"
            className=""
          />
      </div>

    </div>
  );
};

export default Hero;
